import './ChatInputField.css'
import IconPaperClip from "assets/vectors/IconPaperClip";
import IconPaperAirplane from "assets/vectors/IconPaperAirplane";
import {useState} from "react";
import AutoHeightTextArea from "components/core/AutoHeightTextArea/AutoHeightTextArea";
import {toast} from "react-toastify";

const ChatInputField = ({messageSubmitEnabled, onSubmit}) => {

    const [textContent, setTextContent] = useState('')

    const onSendMessageTriggered = () => {
        // get the text content
        const content = textContent;
        // check if the content is empty
        if (content.trim() === '') {
            return;
        }
        // check if the messages is an array
        if (! messageSubmitEnabled) {
            return;
        }
        // clear the textarea
        setTextContent('');
        // submit the message
        onSubmit(content);
    }

    const onAttachmentUploadTriggered = () => {
        toast.info('Attachment upload is not supported yet 😅');
    }

    return (
        <form className={'chat-input-field'}>
            <AutoHeightTextArea
                className={'text-area medium-scroll-bar'}
                placeholder={'Press Ctrl + Enter to send message...'}
                value={textContent || ''}
                onChange={(e) => setTextContent(e.target.value)}
                onKeyDown={(e) => {
                    if (e.key === 'Enter' && e.ctrlKey) {
                        e.preventDefault();
                        onSendMessageTriggered();
                    }
                }}
            />
            <div
                className={'attachment-upload button'}
                onClick={onAttachmentUploadTriggered}
            >
                <IconPaperClip/>
            </div>
            <div
                className={`send button ${!messageSubmitEnabled? 'disabled' : ''}`}
                onClick={onSendMessageTriggered}
            >
                <IconPaperAirplane/>
            </div>
        </form>
    )
}

export default ChatInputField;